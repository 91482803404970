import React from "react"
import { graphql } from "gatsby"
import SEO from "../components/seo"
import Img from 'gatsby-image'
import "../styles/showroom.css"
import "../styles/about.css"
import Price from "../components/Price"
import Input from "../components/form/Input";
import Slider from "react-slick";

export default ({ data }) => {
    const sliderOptions = {
        variableWidth: true,
        slidesToShow: 1,
        infinite: false,
        responsive: [{
            breakpoint: 900,
            settings: {
              slidesToShow: 1,
              centerMode: false,
              variableWidth: false,
              arrows: true
            }
        },
        {
          breakpoint: 600,
          settings: {
            variableWidth: false,
            slidesToShow: 1,
            arrows: true
          }
        }]
      }
    
  return (
    <>
        <SEO title={"Simulator"} />
        <section id="showroom">
            <h2 className="heading">Simulator</h2>
            <Img fluid={data.directusSimulator.main_image.localFile.childImageSharp.fluid} className="opaque50 about--image-top" />
            {data.directusSimulator.catch_text.length > 0 && <p className="about--catch">{data.directusSimulator.catch_text}</p>}
            <div className="opaque80 about--text" dangerouslySetInnerHTML={{__html: data.directusSimulator.text}}></div>
        </section>
            {data.directusSimulator.gallery_item !== null && (
            <section className="news--gallery">
                <Slider {...sliderOptions} className="news--gallery-slider">
                    {data.directusSimulator.youtube_video !== undefined && <div style={{
                        height: ((data.directusSimulator.youtube_video.height / data.directusSimulator.youtube_video.width ) * 40 ) + "rem",
                        width: '40rem',
                        display: 'block',
                        paddingRight: '1rem'
                    }}>
                    {data.directusSimulator.youtube_video.embed.length > 0 &&
                        <iframe 
                            src={`https://www.youtube.com/embed/${data.directusSimulator.youtube_video.embed}`}
                            frameBorder={0}
                            style={{
                                width: '100%',
                                padding: '0 1rem',
                                height: ((data.directusSimulator.youtube_video.height / data.directusSimulator.youtube_video.width ) * 40 ) + "rem",
                            }}
                            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                            webkitallowfullscreen="true"
                            mozallowfullscreen="true"
                            allowFullScreen={true}
                            title={data.directusSimulator.youtube_video.title}
                        />}
                    </div>}
                    {data.directusSimulator.gallery_item.map((item, index) => {
                        return(
                        <figure className="news--gallery-item" key={index} style={{
                            width: 'auto'
                        }}>
                            <Img fluid={item.image.localFile.childImageSharp.fluid}/>
                            {item.name !== null && (
                            <figcaption>{item.name}</figcaption>
                            )}
                        </figure>
                        )
                    })}
                </Slider>
            </section>
            )}
        <section className="showroom">
            <section className="showroom--prices-container">
                <h3>Priser</h3>
                <section className="showroom--prices">
                    {data.site.siteMetadata.project === "_" 
                        ?   <>
                                <Price 
                                    top="Grand prix"
                                    text={<><ul><li>10 min. videoinstruktion</li><li>10 min. træning</li><li>15 min. kvailfikation</li><li>20 min. race</li></ul><p>Gruppe størrelse 2-15 personer</p><p>Frit valg af biler og baner</p></>}
                                    priceText="pr. person + moms"
                                    price={495}
                                />
                                <Price 
                                    top="Le-mans"
                                    text={<><ul><li>10 min. videoinstruktion</li><li>10 min. træning</li><li>10 min. kvailfikation</li><li>60 min. race</li></ul><p>Gruppe størrelse 2-15 personer</p><p>Frit valg af biler og baner</p></>}
                                    priceText="pr. person + moms"
                                    price={749}
                                />
                                <Price 
                                    top="Sprint heats"
                                    text={<><ul><li>10 min. videoinstruktion</li><li>10 min. træning</li><li>2 omgangs kvailfikation</li><li>7 heats af 8 omgange</li><li>Final 10 omgange</li></ul><p>Gruppe størrelse 5-35 personer</p><p>Frit valg af biler og baner</p></>}
                                    priceText="pr. person + moms"
                                    price={749}
                                />
                                <Price 
                                    top="Fastest lap"
                                    text={<><ul><li>10 min. videoinstruktion</li><li>20 min. træning</li><li>2 omgangs kvailfikation</li><li>Hurtigst tid vinder en F1 kører selv</li><li>Vinder findes den 1 sep. 2021</li></ul><p>Gruppe størrelse 1-5 personer</p><p>Udvalgt bil og bane</p></>}
                                    priceText="pr. person + moms"
                                    price={495}
                                />
                            </>
                        : <Price top="1 time fra" price={data.directusSimulator.pris} priceText=" + moms pr. simulator" incl="videoinstruktion og vejl. til baner, gear m.m." extra="*"/>}
                </section>
                {data.site.siteMetadata.project !== "_" && <p className="showroom--prices-info"><sup>*</sup>{data.directusSimulator.pris_extra}</p>}
            </section>
            <section className="showroom--contact-block">
                <h3>Kontakt os</h3>
                <form data-netlify="true" method="POST" name="Simulator" netlify-honeypot="bot-field" netlify="true" action="/thank-you">
                    <div>
                        <p style={{visibility: 'hidden', position: 'absolute'}}>
                            <label htmlFor="bot">Don’t fill this out if you're human: <input id="bot" name="bot-field" type="hidden" aria-label="Don’t fill this out if you're human:"/></label>
                        </p>
                        <Input name="name" type="text" required={true}>Dit navn</Input>
                        <Input name="email" type="email" required={true}>Din emailadresse</Input>
                        <Input name="phone" type="text" required={true}>Dit telefonnummer</Input>
                        <Input name="virksomhed" type="text">Virksomhed</Input>
                    </div>
                    <div>
                        <div className="horizontal">
                            <Input name="date" type="date">Dato</Input>
                            <Input name="person" type="number" other={{min: "5"}}>Antal personer</Input>
                            <Input name="hours" type="number" other={{min: "1"}}>Antal timer pr. person</Input>
                        </div>
                        {/* <div className="horizontal mb--input-radio">
                            <span>Længde</span>
                            <Input name="length" type="radio" other={{value: "3 timer"}}>3 timer</Input>
                            <Input name="length" type="radio" other={{value: "Hel dag"}}>Hel dag</Input>
                            <Input name="length" type="radio" other={{value: "Anden"}}>Anden</Input>
                        </div> */}
                        <div className="mb--input-block">
                            <label htmlFor="comments">Ekstra kommentarer<textarea name="text" id="comments" rows="8" aria-label="Ekstra kommentarer"></textarea></label>
                        </div>
                    </div>
                    <input type="hidden" name="form-name" value="showroom" aria-label="Hidden: Showroom"/>
                    <button type="submit">SEND</button>


                </form>
            </section>
        </section>


    </>
  )
}

    export const query = graphql`
    query {
        site {
            siteMetadata {
                project
            }
        }
        directusSimulator {
            catch_text
            main_image {
                localFile {
                    childImageSharp {
                        fluid(quality: 70) {
                            ...GatsbyImageSharpFluid_withWebp            
                        }
                    }
                }
            }
            text
            gallery_item {
                name
                image {
                    localFile {
                        childImageSharp {
                            fluid(quality: 70, maxWidth: 500) {
                                ...GatsbyImageSharpFluid_withWebp            
                            }
                        }
                    }
                }
            }
            pris
            pris_extra
        }
    }
`
